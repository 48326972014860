import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

import "./Share.scss";

interface ISocialConfig {
  twitterHandle: string;
  config: {
    url: string;
    title: string;
  };
}

interface IShareProps {
  socialConfig: ISocialConfig;
  tags?: string[];
}

const Share = ({ socialConfig, tags }: IShareProps) => {
  const url = socialConfig.config.url;
  return (
    <div className="post-social">
      Share:
      <div className="social-buttons">
        <FacebookShareButton
          url={socialConfig.config.url}
          className="button is-outlined is-rounded facebook"
        >
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton
          url={socialConfig.config.url}
          title={socialConfig.config.title}
          via={socialConfig.twitterHandle.split("@").join("")}
          hashtags={tags}
        >
          <TwitterIcon />
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          className="button is-outlined is-rounded linkedin"
          title={socialConfig.config.title}
        >
          <LinkedinIcon />
        </LinkedinShareButton>
        <RedditShareButton
          url={url}
          className="button is-outlined is-rounded reddit"
          title={socialConfig.config.title}
        >
          <RedditIcon />
        </RedditShareButton>
        <WhatsappShareButton
          url={url}
          className="button is-outlined is-rounded whatsapp"
          title={socialConfig.config.title}
        >
          <WhatsappIcon />
        </WhatsappShareButton>
        <EmailShareButton
        url={url}
        subject={socialConfig.config.title}>
            <EmailIcon />
        </EmailShareButton>
      </div>
    </div>
  );
};

Share.defaultProps = {
  tags: []
};

export default Share;
